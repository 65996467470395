import * as React from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import { Query } from '@generated/graphql'

import Layout from '@components/Layout'
import { Grid, Box, H3, H4 } from '@components/UI'

const Title = styled(H3).attrs({
  pb: 2
})``

const MixtapeBox = styled(Box).attrs({
  pb: [2, 3]
})``

const VideosGrid = styled(Grid).attrs({
  spacing: 4,
  numCols: 1
})``

const Mixtapes = ({ data }: { data: Query }) => {
  const sources = _.get(
    data,
    'allMarkdownRemark.nodes[0].frontmatter.scSources',
    []
  )
  return (
    <Layout title="Mixtapes">
      <VideosGrid
        elements={sources}
        render={({
          title,
          scTrackId
        }: {
          title: string
          description: string
          scTrackId: string
        }) => {
          return (
            <MixtapeBox>
              <Title>{title}</Title>
              <iframe
                title={title}
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${scTrackId}&color=%23ff5500&auto_play=false&hide_related=true&show_comments=true&show_user=true&show_reposts=true&show_teaser=true&show_artwork=true`}
              />
            </MixtapeBox>
          )
        }}
      />
    </Layout>
  )
}

export default Mixtapes

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content.mixtapes.md/" } }
    ) {
      nodes {
        frontmatter {
          scSources {
            scTrackId
            title
          }
        }
      }
    }
  }
`
